<template>
    <v-select :label="name" :value="id" @input="changed" :items="types" required ></v-select>
</template>

<script>
import enums from '@/services/enums';

export default {
  props: ['value', 'label'],
  methods: {
    changed(value) {
      this.$emit('input', value);
    },
  },
  computed: {
    types() {
      return enums.manual_reserve_transaction_type.map((c) => ({ value: c, text: c }));
    },
  },
};
</script>
